// Override Ant Design classes
.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
  color: $gray-8;
  background-color: $menu-select-yellow;
}

.ant-menu-item-selected a {
  color: $gray-8;
}

a:active {
  color: $gray-8;
}

.input {
  background-color: $brand-grey;
  color: $brand-yellow;
  border-style: none none solid none;

  &:hover {
    border-style: none none solid none;
    border-bottom-color: $brand-yellow;
  }

  &:focus {
    outline: none;
    border-style: none none solid none;
  }

  > * {
    background-color: $brand-grey;
  }
}

.ant-checkbox-wrapper:hover .ant-checkbox-inner,
.ant-checkbox:hover .ant-checkbox-inner,
.ant-checkbox-input:focus + .ant-checkbox-inner,
.ant-checkbox-checked::after {
  border-color: $brand-yellow;
}

.ant-checkbox-inner {
  background-color: $brand-grey;
  border: 1px solid $brand-yellow;
}

.ant-checkbox-checked .ant-checkbox-inner {
  background-color: $brand-grey;
  border-color: $brand-yellow;

  &::after {
    position: absolute;
    display: table;
    border: 2px solid $brand-yellow;
    border-top: 0;
    border-left: 0;
    transform: rotate(45deg) scale(1) translate(-50%, -50%);
    opacity: 1;
    transition: all 0.2s cubic-bezier(0.12, 0.4, 0.29, 1.46) 0.1s;
    content: " ";
  }
}

.ant-menu > .loggedMenu:first-child a {
  color: $brand-yellow;
  font-weight: bolder;
}

.ant-layout-sider-children .ant-menu {
  background-color: $card-background;
}

.deal-menu .ant-menu-item {
  color: black;
  border: 1px solid $gray-6;
  margin: 0 !important;
  padding: 5px 20px !important;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  background-color: $card-background;
}

@media screen and (max-width: 500px) {
  .deal-menu .ant-menu-item {
    padding: 5px 7px !important;
  }
}

.deal-menu .ant-menu-item-selected {
  background-color: $gray-6;
}

.deal-menu .ant-menu-item:hover {
  border-bottom-color: $gray-6 !important;
  background-color: $gray-8;
  color: white !important;
}

.ant-steps-item-process
  > .ant-steps-item-container
  > .ant-steps-item-tail::after {
  background-color: #d0d0d0;
}

.deal-menu .ant-menu-item-disabled {
  color: black;
  border: 1px solid $gray-6 !important;
  margin: 0 !important;
  padding: 5px 20px !important;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  opacity: 0.7;

  &:hover {
    background: transparent;
    color: rgba(0, 0, 0, 0.25) !important;
  }
}

.ant-select-dropdown {
  width: 400px !important;
  max-width: 80% !important;
}
