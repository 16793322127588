.Modal {
  position: fixed;
  top: 10%;
  left: 50%;
  transform: translateX(-50%);
  z-index: 500;
  background-color: white;
  min-width: 30%;
  width: fit-content;
  max-width: 90%;
  border: 1px solid #ccc;
  padding: 15px 20px;
  box-sizing: border-box;
  transition: all 0.6s ease-out;
  border-radius: 15px;
  max-height: 80%;
  overflow: hidden;
}

@media screen and (max-width: 774px) {
  .Modal {
    min-width: 70%;
  }
}
