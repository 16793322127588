@import "./ant_styles_override";

html {
  box-sizing: border-box;
  overflow: scroll;
  overflow-x: hidden;
}
// ::-webkit-scrollbar {
//   width: 0; /* Remove scrollbar space */
//   background: transparent; /* Optional: just make scrollbar invisible */
// }

*,
*:before,
*:after {
  box-sizing: inherit;
  margin: 0;
  padding: 0;
}

.transparent {
  background: transparent;
}

.tooltip {
  position: absolute;
  line-height: 16px !important;
  z-index: 10;
  height: 20px;
  top: 37px;
  left: 17px;
}
.tooltip-privacy {
  position: absolute;
  line-height: 16px !important;
  z-index: 10;
  height: 20px;
  top: -25px;
  right: -10px;
}
.tooltip div {
  color: black;
  border: 1px solid #ccc;
  padding: 1.5px;
  padding-top: 0px;
  border-radius: 5px;
  background-color: #f4f4f4;
  height: 20px;
  // line-height: unset !important;
  position: relative;
  // top: -60px;
  // left: -110%;
}
.tooltip-privacy div {
  color: black;
  border: 1px solid #ccc;
  padding: 1.5px;
  padding-top: 0px;
  border-radius: 5px;
  background-color: #f4f4f4;
  height: 20px;
  // line-height: unset !important;
  position: relative;
  // top: -60px;
  // left: -110%;
}

body {
  font-family: "Kumbh Sans", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

ul {
  margin: 0;

  li {
    list-style: none;
  }
}

nav {
  color: $brand-yellow;
}

.form {
  background-color: $brand-grey;
  max-width: 320px;
  min-width: 320px;
  text-align: center;
  margin: 0 auto;
  padding: 24px;

  h3,
  input {
    color: $brand-yellow;
  }

  .form-options {
    display: flex;
    justify-content: space-between;

    span {
      color: $gray-6;
    }
  }

  button {
    width: 100%;
    border: none;
    background: $gradient-1-buttons;
    transition: background 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);

    &:hover {
      background: $brand-yellow;
      border-color: $brand-yellow;
    }

    &:active,
    &:focus {
      background: $brand-yellow;
      border-color: $brand-yellow;
    }

    span {
      color: $brand-grey;
    }
  }

  p {
    color: $gray-8;
  }

  span {
    color: $brand-yellow;
    cursor: pointer;
  }

  a,
  .form-link {
    color: $brand-yellow;
    cursor: pointer;
  }
}

.form-inner {
  margin: 10px 0;

  > * {
    margin-top: 22px;

    &:first-child {
      margin-top: 1px;
    }
  }
  .registerBtn {
    margin: 40px 0 20px 0;
  }
}

.form-container {
  margin: 10px auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.register-form {
  h3,
  label {
    color: $gray-6;
  }

  span {
    color: $brand-yellow;
    cursor: pointer;
  }
}

.content-container {
  overflow-x: hidden;
  background: #fff;
}

.content-card {
  a {
    color: $brand-teal;
    text-decoration: underline;
    font-weight: bold;
  }
}

.heading-desktop {
  display: none;
}

.dashboard {
  background: $gradient-2-backgrounds;
}

// Needs fixing on mobile views
.footer {
  display: none;
}

.content-card {
  padding: 30px;
  // align-items: center;
  h3 {
    margin: 0px;
    line-height: 33.0015px;
  }
  h2 {
    margin: 0px;
  }
}

.accept-btn {
  background-color: lightgreen;
  margin-right: 10px;
}
.accept-label {
  display: inline;
  background-color: #b7ff96;
  margin-left: 25px;
  padding: 5px 10px;
  border: 1px solid $gray-6;
  border-radius: 12px;
  vertical-align: super;
  user-select: none;
  color: $gray-8;
}
.accept-btn:hover {
  background-color: limegreen;
  color: white;
}
.decline-btn {
  background-color: rgb(228, 108, 108);
  margin-left: 10px;
  color: black;
}
.decline-btn:hover {
  background-color: red;
  color: white;
  cursor: pointer;
}

.about,
.how-it-works,
.why-choose-us {
  justify-content: space-evenly;
  text-align: center;
  background-color: $brand-yellow;
}

.menu-btm-row {
  background: #fff;
  box-shadow: 4px 4px 12px rgba(0, 0, 0, 0.35);
}

.content-sub {
  background: #fff;
}

.contact {
  justify-content: space-evenly;
  text-align: center;
  background-color: none;

  div {
    margin-bottom: 10px;
    background-color: $brand-yellow;
  }
}

.landing-sub {
  background: linear-gradient(
    to left,
    #ffd700 -66.17%,
    rgba(255, 215, 0, 0) 50.08%
  );
}

.icon {
  &:hover {
    cursor: pointer;
  }
}

.external-link {
  color: $brand-teal;
  text-decoration: underline;
  font-weight: bold;
}

.dashboard-sub {
  background: $gradient-2-backgrounds;
  color: $gray-8;

  a {
    color: $gray-8;
  }
}

.dashboard-title {
  font-size: 30px;
  color: rgb(58, 56, 56);
  font-weight: bold;
}

.dashboard-title-link:hover {
  text-decoration: underline;
}

.page-content {
  padding: 10px 10px;
  margin-left: 45px;
  margin-right: 45px;
  margin-top: 30px;
}

.border {
  border: 2px solid $gray-6;
}

.dashboard-container-name-hover:hover {
  text-decoration: underline;
  text-decoration-color: lightskyblue;
}

.dashboard-header {
  align-items: center;
  padding: 0 40px;
}

.dashboard-subtitle-icon {
  font-size: 24px;
  color: #3a3838;
  margin: 0 15px;
}

.dashboard-profile-section {
  display: flex;
  flex-direction: row-reverse;
  text-align: center;
  padding-top: 10px;
}
.dashboard-profile-section .logoutButton {
  background-color: #f4f4f4;
  color: black;
  border: 1px solid #ccc;
  border-radius: 5px;
}
.dashboard-profile-section .logoutButton:hover {
  opacity: 0.7;
  cursor: pointer;
}

.gls-nav-logo {
  vertical-align: middle;
}

.custom-date-picker {
  width: 100%;
}

.ant-row-header {
  margin: 8px;
}

.menu-card-link-container {
  padding: 32px 0;
}

.documentId:hover {
  text-decoration: underline;
  cursor: pointer;
}

.deal-overview {
  background: inherit;
  border: 1px solid $gray-6;
  border-radius: 10px;
  padding: 8px 20px;
  margin-bottom: 20px;
  background-color: $card-background;
}
.deal-overview:hover {
  cursor: pointer;
}

.deal-main-data {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 22px;
}

.deal-description {
  position: relative;
  margin-left: 15px;
}

.short-description {
  align-items: center;
  margin-bottom: 10px;
  // position: absolute;
  // bottom: 0;
  // left: 0;
}

.deal-name {
  font-size: 22px;
}
.deal-name:hover {
  text-decoration: underline;
}

.dealStatus {
  margin-top: 15px;
  margin-bottom: 15px;
}

.profile-property-row {
  padding-top: 20px;
  padding-left: 15px;
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 18px;
}

.profile-property {
  width: 200px;
}

.profile-checkboxes {
  margin-top: 100px;
  margin-bottom: 50px;
  font-size: 18px;
  margin-left: 15px;
}

.profile-property-checkbox {
  width: 300px;
}

.ant-switch-checked {
  // background-color: rgb(50, 241, 50);
  background-color: $brand-yellow;
}

.flex-row {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.flex-row-always {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.spread {
  justify-content: space-between;
}

.notification-table-header {
  font-size: 18px;
  color: $gray-8;
  text-align: center;
  width: 100%;
  margin-bottom: 15px;
}

.notification-header-date {
  width: 150px;
}

.notification-header-dealId {
  width: 150px;
}

.notification-header-message {
  flex: 1;
  text-align: left;
}

.notification {
  width: 100%;
  border: 1px solid $gray-6;
  border-radius: 10px;
  padding: 7px 0;
}

.newNotificationDiv {
  cursor: pointer;
  font-size: 12px;
  position: absolute;
  top: 5px;
  right: 10px;
  text-align: left;
  line-height: 16px;
  user-select: none;
}
.newNotificationSpan2 {
  background-color: rgb(238, 28, 63);
  border-radius: 50%;
  display: inline-flex;
  font-weight: 500;
  height: 19px;
  line-height: 14px;
  min-width: 19px;
  justify-content: center;
}
.newNotificationSpan3 {
  align-items: center;
  color: rgb(255, 255, 255);
  display: flex;
  font-weight: 500;
  font-size: 12px;
  height: 19px;
  justify-content: center;
  padding: 0 5px;
  user-select: none;
  text-align: center;
}

.loggedMenu:hover .newNotificationDiv {
  color: black;
}

.page-content > .notification:nth-child(2n) {
  background-color: white;
}

.page-content > .notification:nth-child(2n + 1) {
  background-color: rgb(219, 218, 218);
}

.notification-table-date {
  width: 150px;
  text-align: center;
  font-size: 16px;
  height: 100%;
  padding: 4px 0;
}

.notification-table-dealId {
  width: 150px;
  text-align: center;
  font-size: 16px;
  height: 100%;
  padding: 4px 0;
}
.notification-table-dealId:hover {
  cursor: pointer;
}

.notification-table-subject {
  flex: 1;
  height: 100%;
  padding: 4px 0;
  font-size: 16px;
}
.notification-table-subject:hover {
  cursor: pointer;
}
.notification-message {
  width: 100%;
  padding: 7px 0;
  padding-left: 20px;
  font-size: 16px;
}

.bold {
  font-weight: bolder;
  color: black;
}

.notification-button {
  width: 100%;
  margin-top: 40px;
}

.notification-button .button {
  display: block;
  width: 25%;
  margin: auto;
  border-radius: 15px;
}

.notification-button .button:hover {
  background-color: rgb(219, 218, 218);
  color: black;
  border: 2px solid $brand-yellow;
}

.notification-icon {
  font-size: 25px;
  margin-right: 20px;
  color: $gray-6;
  font-weight: bold;
}

.notification-icon:hover {
  cursor: pointer;
  opacity: 0.3;
}

.dashboard-welcome {
  font-size: 18px;
}

.dashboard-text {
  font-size: 16px;
  max-width: 700px;
}

.pending-deal-name {
  font-size: 25px;
  font-weight: bold;
  margin-bottom: 8px;
  padding-left: 15px;
  color: #2c9dde;
}

.pending-deals-section {
  background-color: rgb(235, 222, 222);
  // background-color: #b7ff96;
  padding: 10px;
  font-size: 17px;
  border: 1px solid $gray-6;
  border-radius: 7px;
  display: flex; //za manje od width: 100% treba biti display inline flex
  flex-direction: row;
  margin-bottom: 25px;
  color: black;
}

.pending-deals-section a {
  color: black;
}

.pending-deals-section a:hover {
  color: black;
  text-decoration: underline;
  cursor: pointer;
}

.deals-info-icon {
  margin: 0 20px;
  font-size: 20px;
  color: black;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.deals-info-click {
  color: rgb(70, 70, 255);
  font-weight: 450;
}

.contact-person {
  color: $brand-teal;
  text-decoration: underline;
}

.deal-menu {
  display: inline-block;
  background-color: transparent;
  border: none;
  line-height: inherit;
}

.deal-details {
  border: 1px solid $gray-6;
  border-radius: 10px;
  border-top-left-radius: 0px;
  font-size: 16px;
  color: black;
  padding: 0 30px;
  box-sizing: border-box;
  padding-bottom: 40px;
  padding-top: 15px;
  background-color: $card-background;
}

.deal-details-row {
  padding-top: 15px;
  padding-bottom: 15px;
  width: 100%;
}

.deal-details-property {
  min-width: 200px;
  max-width: 200px;
  align-self: normal;
  box-sizing: border-box;
}

.deal-details-offer-field {
  margin-left: 10px;
  max-width: 90%;
}

.deal-details-upload {
  background-color: white;
  border: 1px solid $gray-6;
  border-radius: 5px;
  padding: 5px 12px;
}
.deal-details-upload:hover {
  opacity: 0.8;
  cursor: pointer;
}

.pending-deal {
  width: 45%;
  margin: 0 20px;
  display: flex;
  flex-direction: column;
  align-self: normal;
}
.pending-deal-fields {
  border: 1px solid gray;
  border-radius: 10px;
  padding: 25px 15px;
  padding-bottom: 5px;
  flex: 1;
}

.pending-deal-row {
  border-bottom: 1px solid $gray-6;
  padding: 12px 10px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
}
.pending-deal-property {
  font-weight: 500;
  font-size: 18px;
  align-self: normal;
  // min-width: 250px;
  color: black;
}
.pending-deal-value {
  color: #707070;
  align-self: center;
  font-size: 16px;
  flex: 1;
  text-align: end;
}

.uploadedFile {
  margin-left: 25px;
  margin-right: 10px;
  box-sizing: border-box;
  border-radius: 10px;
  margin-bottom: 10px;
}
.uploadedFile:hover {
  background-color: rgb(223, 223, 223);
}
.action-icon {
  padding: 5px;
  margin: 0 5px;
  border-radius: 16px;
  font-size: 16px;
}
.action-icon:hover {
  background-color: gray;
}

.hover:hover {
  cursor: pointer;
}
.pending-offers.flex-row {
  margin-bottom: 55px;
  justify-content: space-evenly;
}

.document-type {
  align-self: center;
}

.deny-option {
  text-align: center;
  font-size: 22px;
  border: 1px solid red;
  border-radius: 20px;
  margin: 0 30px;
  margin-bottom: 20px;
  padding: 10px 20px;
}

.deny-option-p {
  text-align: center;
  font-size: 20px;
}

.deny-option:hover {
  background-color: red;
  cursor: pointer;
  color: white;
}

.deny-option-active {
  background-color: red;
  color: white;
}

.contact-form {
  padding: 0 40px;
  margin: 20px 0;
}

.photoBtn {
  padding: 10px 20px;
  border: 1px solid red;
  background-color: #f0f0f0;
  border-radius: 10px;
  cursor: pointer;
}

.photoBtn:hover {
  opacity: 0.7;
}

.camera {
  width: 1280px;
  height: 720px;
  border-radius: 20px;
  overflow: hidden;
}

.camera.hasPhoto {
  display: none;
}

.photo {
  width: 1280px;
  height: 720px;
  border-radius: 20px;
  overflow: hidden;
  display: none;
}

.photo.hasPhoto {
  display: block;
}

.grecaptcha-badge {
  z-index: 5;
}

.requestOfferFullName {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.requestOfferNameField {
  width: 48%;
  // margin-bottom: 30px;
}
