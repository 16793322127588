$brand-grey: #2e2e2e;
$gray-8: #595959;
$gray-6: #bfbfbf;
$card-background: #f0f0f0;
$gray-1: #e8e8e8;
$brand-teal: #1085b5;
$polar-green-success: #389e0d;
$volcano-6-warning: #fa541c;
$brand-yellow: #ffd700;
$menu-select-yellow: #ffee96;
$gradient-1-buttons: linear-gradient(90deg, #ffd700 0%, #ffffff 114.17%);
$gradient-2-backgrounds: linear-gradient(
  to left,
  #ffd700 -66.17%,
  rgba(255, 215, 0, 0) 50.08%
);
