@import "~antd/dist/antd.css";
@import "./colors";
@import "./base";

/* Additional Mobile Styles */
/* Screens up to 414px */
@media screen and (max-width: 774px) {
  .pending-offers.flex-row {
    flex-direction: column;
  }
  .pending-deal {
    margin-bottom: 55px;
    align-self: center;
    width: 65%;
  }
  .deny-option {
    font-size: 18px;
    padding: 7px 14px;
    margin: 0 10px;
    margin-bottom: 20px;
  }
  .deny-option-p {
    font-size: 18px;
  }
}

@media screen and (max-width: 550px) {
  .deal-details-property {
    min-width: 150px;
    max-width: 150px;
  }
  .not-visible-on-mobile {
    display: none;
  }
}

@media screen and (max-width: 500px) {
  .form-margin {
    flex-grow: 1;
  }
  .landing-sub {
    background: $brand-grey;
  }
  .form {
    box-shadow: none;
  }
  .pending-deal {
    width: 100%;
  }
  .page-content {
    padding-left: 0;
    padding-right: 0;
  }
  .deny-option {
    font-size: 16px;
    padding: 5px 10px;
    margin: 0 10px;
    margin-bottom: 14px;
  }
  .deny-option-p {
    font-size: 16px;
  }
  .notification-table-date {
    width: min-content;
    margin: 0 10px;
  }

  .notification-table-dealId {
    width: min-content;
    text-align: center;
    font-size: 16px;
    margin: 0 10px;
    height: 100%;
    padding: 4px 0;
  }

  .notification-table-message {
    flex: 1;
    margin: 0 10px;
    height: 100%;
    padding: 4px 0;
    font-size: 16px;
    max-height: 76px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .deal-details {
    padding-left: 20px;
    padding-right: 20px;
  }
  .deal-details-property {
    min-width: unset;
    max-width: unset;
    margin-bottom: 10px;
  }
  .deal-details .flex-row {
    flex-direction: column;
    align-items: flex-end;
    margin-bottom: 10px;
  }
  .uploadedFile {
    align-self: flex-start;
    margin-right: 0;
  }
  .action-icon {
    padding: 5px;
    margin: 0 0px;
  }
}

@media screen and (max-width: 420px) {
  .requestOfferFullName {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 100%;
  }
  .requestOfferNameField {
    width: 100%;
  }
}

/* Tablet Styles */
/* Screens with minimum width of 768px */
@media screen and (min-width: 768px) {
  .heading-desktop {
    display: block;
    text-align: center;

    h2 {
      font-size: 36px;
    }

    p {
      font-size: 20px;
    }
  }

  .register-form {
    margin: 0 auto;
  }

  .footer {
    display: block;
    margin: 0;

    nav {
      max-width: 734px;
      margin: 0 auto;
      color: #fff;
    }
  }
}

/* Landscape screens up to 896px */
@media screen and (max-width: 896px) and (orientation: landscape) {
  .form-margin {
    flex-grow: 1;
  }

  .mobile-tablet-show {
    display: none;
  }

  .landing-sub {
    background: $brand-grey;
  }

  .form {
    box-shadow: none;
  }
}

/* Screens with minimum width of 991px */
@media screen and (min-width: 991px) {
  .contact {
    background-color: $brand-yellow;
  }
}

/* Desktop Styles */
/* Screens up to 1199px */

@media screen and (min-width: 1024px) {
  .ant-row-header,
  .menu-btm-row,
  .dashboard-menu-btm-row {
    max-width: 783px;
    margin: 0 auto;
  }
}

@media screen and (max-width: 1199px) {
  .content-sub,
  .dashboard-sub,
  .landing-sub {
    min-width: 100%;
  }
  .content-container,
  .content-sub,
  .dashboard-sub,
  .landing-sub {
    background: none;
  }
}

/* Screens with minimum width of 1200px */
@media screen and (min-width: 1200px) {
  .desktop-header {
    margin: 60px 0;
  }

  .only-mobile {
    display: none;
  }

  header nav {
    margin: 0 120px;
  }

  .content-sub,
  .dashboard-sub {
    max-width: 894px;
  }

  .form-logo {
    display: none;
  }
  .login-form,
  .read-offer-form {
    // max-height: 400px;
    // min-height: 400px;
    padding-bottom: 50px;
  }

  .heading-desktop {
    display: block;
    min-width: 391px;

    h2 {
      font-size: 48px;
    }

    p {
      font-size: 24px;
    }
  }

  .content-container,
  .content-sub,
  .dashboard-sub,
  .landing-sub {
    background: none;
  }

  .dashboard-margin {
    margin: 0 8.4rem;
  }

  .content-container {
    margin: 0 120px;
  }
}

/* Screens with minimum width of 1440px */
@media screen and (min-width: 1440px) {
  header nav {
    width: 1200px;
    margin: 0 auto;
  }

  .content-container {
    width: 1200px;
    margin: 0 auto;
  }
}
